import functionRequester from 'utils/network/functionRequester';
import 'firebase/database';
import {setCommunityInfo, setCommunityMembership, setNewPosts, updatePost} from 'services/redux/actions/communityActions';
import {setPostReactions, setAllPostsReactions} from 'services/redux/actions/postActions';
import {setPostData} from "services/redux/actions/postActions";
import SessionCache from "services/SessionCache"; 
import {POST_CONTAINER_CACHE_KEY, POST_CONTAINER_CACHE_DURATION, COMMUNITY_INFO_CACHE_DURATION,
    COMMUNITY_PRIMARY_INFO_CACHE_SUFFIX, POST_NOT_FOUND} from 'utils/consts/communityConsts';

const GetCommunityInfo = (handle, getCommunityPrimaryInfo, getMembershipConfig, dispatcher)=>
{
    try{
    handle = handle.toLowerCase();
    const username = getMembershipConfig.username?getMembershipConfig.username : "null" ;
    const cachedInfo = getCommunityPrimaryInfoFromCache(handle);
    if(cachedInfo) 
    {
        dispatcher(setCommunityInfo(cachedInfo.data));
    }
    
    functionRequester.get('community/'+handle+"/"+username).then((res)=>
    {
        console.log(res);
        const data = res.data;
        data.id = handle;
        const membership = data.user_membership;
        if(getCommunityPrimaryInfo)
        {
            if(data.user_membership)
            {
                delete data['user_membership'];
            }
            dispatcher(setCommunityInfo(data));
            cacheCommunityPrimaryInfo(handle, data);
        }
        dispatcher(setCommunityMembership(membership));
    })
    }
    catch(e)
    {
        console.log(e);
    }
}

const ReactToPost = (dispatcher, slug, username, post,
                     handle, is_post = true)=>
{
    try
    {
        let body = {
            slug: slug,
            username: username,
            post_id: post.id,
            community_handle: handle,
            is_post: is_post
        }
      /*  let reaction = post.reactions.find(r => r.slug === slug);
        if(reaction)
        {
           if(reaction.count <2) {
                post.reactions.splice(post.reactions.indexOf(reaction), 1);
            }
            else
            {
               const i = post.reactions.indexOf(reaction);
               post.reactions[i].count--;
            }
        }
        else
        {
          post.reactions.push({slug: slug});
        }
        //dispatcher(setPostReactions(post.id, post.reactions));*/
        functionRequester.post('post/react', body).then((res)=> 
        {
            const data = res.data;
            if(!data)
            {
                return;
            }
            console.log("DATA: ");
            console.log(data);
            dispatcher(setPostReactions(data.id, data.reactions));
        });
    }
    catch(e)
    {
        console.log(e);
    }
}

function getCommunityPrimaryInfoFromCache(id)
{
    return SessionCache.GetItem(id+COMMUNITY_PRIMARY_INFO_CACHE_SUFFIX);
}

function cacheCommunityPrimaryInfo(id, data)
{
    SessionCache.SetItem(id+COMMUNITY_PRIMARY_INFO_CACHE_SUFFIX, data, COMMUNITY_INFO_CACHE_DURATION);
}

const RequestCommunityRoleUpdate = async (dispatcher, user_id, handle, join) =>{
    try{
        let body = {username: user_id, handle: handle, join: join};
        const res = await functionRequester.post('community/user-membership-action',body);
        const data = res.data;
        dispatcher(setCommunityMembership(data.membership));
    }
    catch(e)
    {
    }
    return;
}

const GetRecentPosts = async(dispatcher, handle, section_id = null) =>{
    try{
        const key = `${POST_CONTAINER_CACHE_KEY}_${handle}_${section_id??''}`;
        const cachedInfo = SessionCache.GetItem(key);
        if(cachedInfo && cachedInfo.data && !cachedInfo.expired)
        {
            dispatcher(setNewPosts(cachedInfo.data.data));
            return;
        }
        let body = {handle: handle, section_id: section_id};
        const res = await functionRequester.get('community/'+handle+"/posts/" + (section_id !== null? section_id : '0'));
        let data =  res.data;
        if(!data || data == 'null' )
        {
            data = [];
        }
        SessionCache.SetItem(key, {data: data}, POST_CONTAINER_CACHE_DURATION);
        dispatcher(setNewPosts(data));
        dispatcher(setAllPostsReactions(data));
    }
    catch(e)
    {
    }
};

const GetPost = async(dispatcher, handle, post_id)=>{
    try{
        const res = await functionRequester.get('community/'+handle+"/post/"+post_id);
        const data = res.data;
        if(!data.found)
        {
            
            dispatcher(setPostData({post: POST_NOT_FOUND, postFound:false}));
        }
        else 
        {
            dispatcher(setPostData({post: data.post, postFound:true}));    
        }
    }
    catch(e)
    {
    }
}

async function CreateNewPost(data){
    try{
        const res = await functionRequester.post('community/'+data.handle+"/create-post", data);
        console.log(res.data);
        return res.data;
    }
    catch(e)
    {
    }
};

export {CreateNewPost, GetCommunityInfo,
    RequestCommunityRoleUpdate, GetRecentPosts, GetPost, ReactToPost};