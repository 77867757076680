import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setCommunityLoading} from "services/redux/actions/communityActions";
import {GetCommunityInfo} from 'api/communityApi';

/*
#MOBILE
const platform = "mobile";
*/
//#WEB
const platform = "web";
//#END

export default function useCommunityInfo(handle)
{
    const dispatcher = useDispatch();
    const communityData = useSelector(state => state.communityData);
    const [infoLoaded, setInfoLoaded] = useState(false);
    
    useEffect(()=>{
        const getMembershipConfig = {getMembership: false};

        if(!communityData.primary_info) {
            dispatcher(setCommunityLoading(true))
            GetCommunityInfo(handle, true, getMembershipConfig, dispatcher);
        }
    },[]);

    useEffect(()=>{
        if(communityData.primary_info)
        {
            dispatcher(setCommunityLoading(false));
            setInfoLoaded(true);
        }
    },[communityData]);
    
    return infoLoaded;
}