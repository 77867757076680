//#!!!#
function SetItem(key, value, seconds)
{
    let valueString = JSON.stringify(Object.assign(value, {
        cache_time: new Date(),
        cache_duration: seconds
    }));
    sessionStorage.setItem(key, valueString);
}

function GetItem(key)
{
    const result ={expired: null, data: null};
    const itemString = sessionStorage.getItem(key);
    if(!itemString)
    {
        return null;
    }

    try {
        const item =  JSON.parse(itemString);
        if(!item.cache_time)
        {
            return result;
        }
        const duration = (new Date().valueOf() - new Date(item.cache_time).valueOf()) / 1000.0;
        result.expired = duration > item.cache_duration;
        result.data = item;
    }
    catch(e)
    {
    }
    return result;
    
}

function DeleteItem(key)
{
    try{
        if(sessionStorage.getItem(key))
        {
            sessionStorage.removeItem(key);
        }
    }
    catch(e)
    {
    }
}

const SessionCache = {GetItem: GetItem, SetItem: SetItem, DeleteItem: DeleteItem};

export default SessionCache;